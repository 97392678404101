import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { _ACTIONS, _COLORS } from "../../../utils/_constants";
import { _STYLES, btn } from "../../../utils/_style_constants";
import { editInstantRequest } from "../../../utils/formHelper";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import _ from "lodash";
import MouseOverPopover from "../MouseOverPopover";
import HelpIcon from "@mui/icons-material/Help";
import MuiPhoneNumber from "mui-phone-number";

function Mandant({
  currentMandat,
  setCurrentMandat,
  refresh,
  typeMandants,
  register,
  errors,
  civilites,
  requestHelper,
  disabledSign = false,
  mandantListNonSignataire,
}) {
  const [boutonMandant, setBoutonMandant] = useState(false);
  const [modeTutelle, setModeTutelle] = useState(false);
  const [phoneValue, setPhoneValue] = useState("");
  const [currentMandant, setCurrentMandant] = useState("");
  let modeHumain = false;
  let modeSociete = false;

  const handleChangeMandant = (value, f, mandant) => {
    setCurrentMandat({
      ...currentMandat,
      mandants: currentMandat.mandants.map((m) => {
        if (m["@id"] === mandant["@id"]) {
          m[f] = value;
        }
        return m;
      }),
    });
  };

  const copyAddress = () => {
    let mandant = currentMandat.mandants[0];
    let nextMandant = currentMandat.mandants[1];
    requestHelper
      .doRequest(_ACTIONS.PUT, nextMandant["@id"], {
        adresse: mandant.adresse,
        portable: mandant.portable,
        email: mandant.email,
      })
      .then(() => {
        refresh();
      });
  };

  const changePhoneValue = (e) => {
    let stringInput = e;
    e = stringInput.replace(/[^\d+]/g, ""); // Keep only numeric characters and "+"

    return e;
  };

  useEffect(() => {
    if (!_.isEmpty(currentMandant["@id"])) {
      editInstantRequest(phoneValue, currentMandant["@id"], "portable");
    }
  }, [phoneValue]);

  useEffect(() => {
    if (currentMandat?.typeMandant?.["@id"] === "/api/type_mandants/4") {
      setModeTutelle(true);
    }
    if (
      currentMandat?.typeMandant?.["@id"] === "/api/type_mandants/3" ||
      currentMandat?.typeMandant?.["@id"] === "/api/type_mandants/4"
    ) {
      setBoutonMandant(true);
    }
  }, [currentMandat]);

  useEffect(() => {
    if (currentMandat?.typeMandant?.["@id"] === "/api/type_mandants/4") {
      setModeTutelle(true);
    }
    if (
      currentMandat?.typeMandant?.["@id"] === "/api/type_mandants/3" ||
      currentMandat?.typeMandant?.["@id"] === "/api/type_mandants/4"
    ) {
      setBoutonMandant(true);
    }
  }, [currentMandat]);

  return (
    <>
      <Grid container columnSpacing={1} rowSpacing={1} padding={4}>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              color: _COLORS.primary,
            }}
          >
            Mandants
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} alignItems={"center"} display={"flex"}>
          <Grid item xs={4} md={4} display={"flex"}>
            <Typography sx={{ ..._STYLES.typographyLabelTitle }}>
              Type de mandant *
            </Typography>{" "}
            <MouseOverPopover
              sx={{
                marginLeft: "10px",
                width: "350px",
              }}
              title={<HelpIcon />}
              popoverText="On considère le mandant comme le propriétaire du bien. Si par exemple Mme vit dans le logement mais n’est pas propriétaire, elle est alors considérée comme un tiers"
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <FormControl fullWidth>
              <InputLabel size="small"> Type de mandant</InputLabel>
              <Select
                label="Type de mandant"
                size="small"
                disabled={currentMandat.type === "avenant" || disabledSign}
                {...register("typeMandant", { required: false })}
                id="typeMandant"
                value={
                  currentMandat?.typeMandant
                    ? currentMandat.typeMandant["@id"]
                    : ""
                }
                onChange={(e) => {
                  setModeTutelle(e.target.value === "/api/type_mandants/4");
                  setBoutonMandant(
                    e.target.value === "/api/type_mandants/3" ||
                      e.target.value === "/api/type_mandants/4"
                  );

                  let m = editInstantRequest(
                    e.target.value,
                    "/api/mandats/" + currentMandat.id,
                    "typeMandant"
                  );

                  m.then((m) => {
                    setCurrentMandat(m);
                  });
                }}
              >
                {typeMandants?.map((typeMandant, i) => (
                  <MenuItem key={i} value={typeMandant["@id"]}>
                    {typeMandant.nom}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {mandantListNonSignataire?.map(
          (mandant, i) => (
            (modeHumain = mandant?.civilite?.id !== 3),
            (modeSociete = mandant?.civilite?.id === 3),
            (
              <Grid container spacing={1} key={i} sx={{ paddingTop: "1em" }}>
                <Grid
                  item
                  md={1}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      ..._STYLES.typographyLabelTitle,
                      ...{ paddingRight: "1em" },
                    }}
                  >
                    {i + 1}
                  </Typography>
                  <FormControl fullWidth>
                    <InputLabel size="small">Civilité</InputLabel>
                    <Select
                      label="Civilité"
                      size="small"
                      disabled={
                        currentMandat?.type === "avenant" || disabledSign
                      }
                      value={mandant.civilite ? mandant.civilite["@id"] : ""}
                      {...register(i + "civilite", { required: false })}
                      id={i + "civilite"}
                      onChange={(e) => {
                        editInstantRequest(
                          e.target.value,
                          mandant["@id"],
                          "civilite"
                        );

                        refresh();
                      }}
                    >
                      {civilites?.map((civilite, i) => (
                        <MenuItem key={i} value={civilite["@id"]}>
                          {civilite.nom}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* Mode Personne */}
                {!modeSociete && modeHumain && (
                  <>
                    <Grid item md={1}>
                      <TextField
                        fullWidth
                        size="small"
                        id={i + "nom"}
                        label="Nom"
                        disabled={
                          currentMandat?.type === "avenant" || disabledSign
                        }
                        value={mandant.nom !== null ? mandant.nom : ""}
                        // Besoin de changer la valeur du state pour que le composant se rafraichisse
                        onChange={(e) => {
                          handleChangeMandant(e.target.value, "nom", mandant);
                        }}
                        // On envoie la valeur au serveur
                        onBlur={(e) => {
                          editInstantRequest(
                            e.target.value,
                            mandant["@id"],
                            "nom"
                          );

                          refresh();
                        }}
                      />
                    </Grid>
                    <Grid item md={1}>
                      <TextField
                        fullWidth
                        size="small"
                        id={i + "prenom"}
                        label="Prénom"
                        disabled={
                          currentMandat?.type === "avenant" || disabledSign
                        }
                        value={mandant.prenom !== null ? mandant.prenom : ""}
                        // Besoin de changer la valeur du state pour que le composant se rafraichisse
                        onChange={(e) => {
                          handleChangeMandant(
                            e.target.value,
                            "prenom",
                            mandant
                          );
                        }}
                        // On envoie la valeur au serveur
                        onBlur={(e) => {
                          editInstantRequest(
                            e.target.value,
                            mandant["@id"],
                            "prenom"
                          );

                          refresh();
                        }}
                      />
                    </Grid>
                    <Grid item md={1.5}>
                      <DatePicker
                        label="Date de naissance"
                        size="small"
                        disabled={
                          currentMandat?.type === "avenant" || disabledSign
                        }
                        value={
                          mandant.dateNaissance
                            ? moment(mandant.dateNaissance)
                            : null
                        }
                        format="DD/MM/YYYY"
                        onChange={(date) => {
                          let dateToSend = date?.toLocaleString("fr-FR", {
                            timeZone: "Europe/paris",
                          });
                          if (dateToSend !== "Invalid date") {
                            editInstantRequest(
                              _.isNull(dateToSend) ? null : dateToSend,
                              mandant["@id"],
                              "dateNaissance"
                            );
                            refresh();
                          }
                        }}
                        slotProps={{
                          textField: {
                            size: "small",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item md={1.5}>
                      <TextField
                        fullWidth
                        size="small"
                        disabled={
                          currentMandat?.type === "avenant" || disabledSign
                        }
                        id={i + "lieuNaissance"}
                        label="Lieu de naissance"
                        value={
                          mandant.lieuNaissance !== null
                            ? mandant.lieuNaissance
                            : ""
                        }
                        // Besoin de changer la valeur du state pour que le composant se rafraichisse
                        onChange={(e) => {
                          handleChangeMandant(
                            e.target.value,
                            "lieuNaissance",
                            mandant
                          );
                        }}
                        // On envoie la valeur au serveur
                        onBlur={(e) => {
                          editInstantRequest(
                            e.target.value,
                            mandant["@id"],
                            "lieuNaissance"
                          );

                          refresh();
                        }}
                      />
                    </Grid>
                  </>
                )}
                {/* Mode Société */}
                {modeSociete && !modeHumain && (
                  <>
                    <Grid item md={2}>
                      <TextField
                        fullWidth
                        size="small"
                        id="nomSociete"
                        label="Nom de la société"
                        disabled={
                          currentMandat?.type === "avenant" || disabledSign
                        }
                        value={
                          mandant.nomSociete !== null ? mandant.nomSociete : ""
                        }
                        onChange={(e) => {
                          handleChangeMandant(
                            e.target.value,
                            "nomSociete",
                            mandant
                          );
                        }}
                        onBlur={(e) => {
                          editInstantRequest(
                            e.target.value,
                            mandant["@id"],
                            "nomSociete"
                          );

                          refresh();
                        }}
                      />
                    </Grid>
                    <Grid item md={1}>
                      <TextField
                        fullWidth
                        size="small"
                        id="capital"
                        label="Capital social"
                        disabled={
                          currentMandat?.type === "avenant" || disabledSign
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">€</InputAdornment>
                          ),
                        }}
                        value={mandant.capital ? mandant.capital : ""}
                        onChange={(e) => {
                          handleChangeMandant(
                            e.target.value,
                            "capital",
                            mandant
                          );
                        }}
                        onBlur={(e) => {
                          editInstantRequest(
                            e.target.value,
                            mandant["@id"],
                            "capital"
                          );

                          refresh();
                        }}
                      />
                    </Grid>
                    <Grid item md={1}>
                      <TextField
                        fullWidth
                        size="small"
                        id="rcs"
                        label="Rcs"
                        disabled={
                          currentMandat?.type === "avenant" || disabledSign
                        }
                        value={mandant.rcs !== null ? mandant.rcs : ""}
                        onChange={(e) => {
                          handleChangeMandant(e.target.value, "rcs", mandant);
                        }}
                        onBlur={(e) => {
                          editInstantRequest(
                            e.target.value,
                            mandant["@id"],
                            "rcs"
                          );

                          refresh();
                        }}
                      />
                    </Grid>
                    <Grid item md={1.5}>
                      <TextField
                        fullWidth
                        size="small"
                        id="rcsVille"
                        label="Rcs Ville"
                        disabled={
                          currentMandat?.type === "avenant" || disabledSign
                        }
                        value={
                          mandant.rcsVille !== null ? mandant.rcsVille : ""
                        }
                        onChange={(e) => {
                          handleChangeMandant(
                            e.target.value,
                            "rcsVille",
                            mandant
                          );
                        }}
                        onBlur={(e) => {
                          editInstantRequest(
                            e.target.value,
                            mandant["@id"],
                            "rcsVille"
                          );

                          refresh();
                        }}
                      />
                    </Grid>
                    <Grid item md={1.5}>
                      <TextField
                        fullWidth
                        size="small"
                        id={i + "nom"}
                        label="Nom du représentant"
                        disabled={
                          currentMandat?.type === "avenant" || disabledSign
                        }
                        value={mandant.nom !== null ? mandant.nom : ""}
                        // Besoin de changer la valeur du state pour que le composant se rafraichisse
                        onChange={(e) => {
                          handleChangeMandant(e.target.value, "nom", mandant);
                        }}
                        // On envoie la valeur au serveur
                        onBlur={(e) => {
                          editInstantRequest(
                            e.target.value,
                            mandant["@id"],
                            "nom"
                          );

                          refresh();
                        }}
                      />
                    </Grid>

                    <Grid item md={1.5}>
                      <TextField
                        fullWidth
                        size="small"
                        id={i + "prenom"}
                        label="Prénom du représentant"
                        disabled={
                          currentMandat?.type === "avenant" || disabledSign
                        }
                        value={mandant.prenom !== null ? mandant.prenom : ""}
                        // Besoin de changer la valeur du state pour que le composant se rafraichisse
                        onChange={(e) => {
                          handleChangeMandant(
                            e.target.value,
                            "prenom",
                            mandant
                          );
                        }}
                        // On envoie la valeur au serveur
                        onBlur={(e) => {
                          editInstantRequest(
                            e.target.value,
                            mandant["@id"],
                            "prenom"
                          );

                          refresh();
                        }}
                      />
                    </Grid>
                    <Grid item md={1}>
                      {" "}
                    </Grid>
                  </>
                )}
                {/* Fin du mode Société */}
                {/* Partie commune */}
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    alignContent: "space-between",
                  }}
                  item
                  md={3}
                >
                  <TextField
                    fullWidth
                    size="small"
                    disabled={currentMandat?.type === "avenant" || disabledSign}
                    id={i + "adresse"}
                    label="Adresse"
                    value={mandant.adresse !== null ? mandant.adresse : ""}
                    // Besoin de changer la valeur du state pour que le composant se rafraichisse
                    onChange={(e) => {
                      handleChangeMandant(e.target.value, "adresse", mandant);
                    }}
                    // On envoie la valeur au serveur
                    onBlur={(e) => {
                      editInstantRequest(
                        e.target.value,
                        mandant["@id"],
                        "adresse"
                      );

                      refresh();
                    }}
                  />
                </Grid>
                {/* Mode Tutelle */}
                {modeTutelle && (
                  <>
                    <Grid item md={2}></Grid>
                    <Grid item md={1.5}>
                    <FormControl fullWidth>
                      <InputLabel size="small">Civilité</InputLabel>
                      <Select
                        label="Civilité du tuteur"
                        size="small"
                        disabled={
                          currentMandat?.type === "avenant" || disabledSign
                        }
                        value={mandant.civiliteTuteur ? mandant.civiliteTuteur["@id"] : ""}
                        {...register(i + "civiliteTuteur", { required: false })}
                        id={i + "civiliteTuteur"}
                        onChange={(e) => {
                          editInstantRequest(
                            e.target.value,
                            mandant["@id"],
                            "civiliteTuteur"
                          );

                          refresh();
                        }}
                      >
                        {civilites?.map((civilite, i) => (
                          <MenuItem key={i} value={civilite["@id"]}>
                            {civilite.nom}
                          </MenuItem>
                        ))}
                      </Select>
                  </FormControl>
                  </Grid>
                    <Grid item md={1.5}>
                      <TextField
                        fullWidth
                        size="small"
                        id="nomTuteur"
                        disabled={
                          currentMandat?.type === "avenant" || disabledSign
                        }
                        label="Nom du tuteur"
                        value={
                          mandant?.nomTuteur !== null ? mandant.nomTuteur : ""
                        }
                        onChange={(e) => {
                          handleChangeMandant(
                            e.target.value,
                            "nomTuteur",
                            mandant
                          );
                        }}
                        onBlur={(e) => {
                          editInstantRequest(
                            e.target.value,
                            mandant["@id"],
                            "nomTuteur"
                          );

                          refresh();
                        }}
                      />
                    </Grid>
                    
                    <Grid item md={1.5}>
                      <TextField
                        fullWidth
                        size="small"
                        id="prenomTuteur"
                        disabled={
                          currentMandat?.type === "avenant" || disabledSign
                        }
                        label="Prénom du tuteur"
                        value={
                          mandant?.prenomTuteur !== null
                            ? mandant.prenomTuteur
                            : ""
                        }
                        onChange={(e) => {
                          handleChangeMandant(
                            e.target.value,
                            "prenomTuteur",
                            mandant
                          );
                        }}
                        onBlur={(e) => {
                          editInstantRequest(
                            e.target.value,
                            mandant["@id"],
                            "prenomTuteur"
                          );

                          refresh();
                        }}
                      />
                    </Grid>
                    <Grid item md={1.5}>
                      <TextField
                        fullWidth
                        size="small"
                        id="nomJugeTuteur"
                        disabled={
                          currentMandat?.type === "avenant" || disabledSign
                        }
                        label="Nom du juge"
                        value={
                          mandant?.nomJugeTuteur !== null
                            ? mandant.nomJugeTuteur
                            : ""
                        }
                        onChange={(e) => {
                          handleChangeMandant(
                            e.target.value,
                            "nomJugeTuteur",
                            mandant
                          );
                        }}
                        onBlur={(e) => {
                          editInstantRequest(
                            e.target.value,
                            mandant["@id"],
                            "nomJugeTuteur"
                          );

                          refresh();
                        }}
                      />
                    </Grid>
                    <Grid item md={1.5}>
                      <TextField
                        fullWidth
                        size="small"
                        id="tribunalTuteur"
                        disabled={
                          currentMandat?.type === "avenant" || disabledSign
                        }
                        label="Ville du tribunal"
                        value={
                          mandant?.tribunalTuteur !== null
                            ? mandant.tribunalTuteur
                            : ""
                        }
                        onChange={(e) => {
                          handleChangeMandant(
                            e.target.value,
                            "tribunalTuteur",
                            mandant
                          );
                        }}
                        onBlur={(e) => {
                          editInstantRequest(
                            e.target.value,
                            mandant["@id"],
                            "tribunalTuteur"
                          );

                          refresh();
                        }}
                      />
                    </Grid>
                    <Grid item md={1.5}>
                      <DatePicker
                        label="Date decision tribunal"
                        size="small"
                        disabled={
                          currentMandat?.type === "avenant" || disabledSign
                        }
                        value={
                          mandant && mandant.dateTribunalTuteur
                            ? moment(mandant.dateTribunalTuteur)
                            : null
                        }
                        format="DD/MM/YYYY"
                        onChange={(date) => {
                          let dateToSend = date?.toLocaleString("fr-FR", {
                            timeZone: "Europe/paris",
                          });
                          if (dateToSend !== "Invalid date") {
                            editInstantRequest(
                              _.isNull(dateToSend) ? null : dateToSend,
                              mandant["@id"],
                              "dateTribunalTuteur"
                            );
                            refresh();
                          }
                        }}
                        slotProps={{
                          textField: {
                            size: "small",
                          },
                        }}
                      />
                    </Grid>
                  </>
                )}
                {/* Téléphone Mail */}
                <Grid item md={1.5}>
                  <MuiPhoneNumber
                    size="small"
                    defaultCountry={"fr"}
                    value={mandant.portable !== null ? mandant.portable : ""}
                    disabled={currentMandat?.type === "avenant" || disabledSign}
                    disableDropdown={
                      currentMandat?.type === "avenant" || disabledSign
                    }
                    inputClass={TextField}
                    fullWidth
                    id={i + "portable"}
                    name={i + "portable"}
                    onChange={(e) => {
                      handleChangeMandant(
                        changePhoneValue(e),
                        "portable",
                        mandant
                      );
                    }}
                    onBlur={(e) => {
                      setCurrentMandant(mandant);
                      setPhoneValue(changePhoneValue(e.target.value));
                    }}
                  />
                </Grid>
                <Grid item md={1.5}>
                  <TextField
                    fullWidth
                    size="small"
                    disabled={currentMandat?.type === "avenant" || disabledSign}
                    id={i + "email"}
                    name={i + "email"}
                    label="Email"
                    value={mandant.email !== null ? mandant.email : ""}
                    // Besoin de changer la valeur du state pour que le composant se rafraichisse
                    onChange={(e) => {
                      handleChangeMandant(e.target.value, "email", mandant);
                    }}
                    // On envoie la valeur au serveur
                    onBlur={(e) => {
                      editInstantRequest(
                        e.target.value,
                        mandant["@id"],
                        "email"
                      );

                      refresh();
                    }}
                  />
                </Grid>
                <Grid container>
                  {errors && errors[i + "portable"] && (
                    <Typography variant="caption" color="red">
                      Le numéro de téléphone doit contenir 10 chiffres
                    </Typography>
                  )}
                  {errors && errors[i + "email"] && (
                    <Typography variant="caption" color="red">
                      Le format de l'email n'est pas bon
                    </Typography>
                  )}
                </Grid>

                {/* Bouton Copier l'adresse */}
                <Grid
                  item
                  md={0.5}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  {currentMandat?.typeMandant["@id"] ===
                    "/api/type_mandants/2" &&
                    i == 0 && (
                      <ArrowDownwardIcon
                        title="Copier les informations du conjoint"
                        sx={{ cursor: "pointer", marginLeft: "1em" }}
                        onClick={copyAddress}
                      ></ArrowDownwardIcon>
                    )}
                </Grid>
                {/** Bouton Supprimer */}
                <Grid
                  item
                  md={0.5}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  {(currentMandat?.typeMandant["@id"] ===
                    "/api/type_mandants/3" ||
                    currentMandat?.typeMandant["@id"] ===
                      "/api/type_mandants/4") &&
                    i > 0 && (
                      <HighlightOffIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          requestHelper
                            .doRequest(_ACTIONS.DELETE, mandant["@id"])
                            .then((data) => {
                              refresh();
                            });
                        }}
                      ></HighlightOffIcon>
                    )}
                </Grid>
              </Grid>
            )
          )
        )}

        {/* Bouton Ajouter un mandant */}
        {boutonMandant && (
          <Grid container>
            <Grid item md={10}></Grid>
            <Grid item md={2}>
              <Box sx={{ flexGrow: 1, padding: 4 }}>
                <Button
                  sx={{
                    ...btn(_COLORS.primary, _COLORS.primaryLight),
                  }}
                  onClick={() => {
                    requestHelper
                      .doRequest(_ACTIONS.POST, "/api/add-new-mandant", {
                        mandat: currentMandat.id,
                      })
                      .then((data) => {
                        refresh();
                      });
                  }}
                >
                  <AddCircleOutlineIcon></AddCircleOutlineIcon>
                  Ajouter un mandant
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default Mandant;
