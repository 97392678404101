import { Checkbox, Grid, TextField, Typography } from "@mui/material";
import MuiPhoneNumber from "mui-phone-number";
import { useEffect, useState } from "react";

function SignerDisplay({ obj, index, setData, data }) {
  const civilite = obj?.civiliteTuteur?.nom ?? (obj?.civilite?.nom ? obj?.civilite?.nom
    : "Civilité non renseignée");
  const nom = obj?.nomTuteur ?? (obj?.nom ? obj?.nom : "Nom non renseigné");
  const prenom =
    obj?.prenomTuteur ?? (obj?.prenom ? obj?.prenom : "Prénom non renseigné");
  const [signer, setSigner] = useState({});
  const [refresh, setRefresh] = useState(false);

  const refreshData = () => {
    setRefresh(!refresh);
  };

  // Set signer data
  useEffect(() => {
    setSigner({
      id: obj?.id,
      civilite: obj?.civiliteTuteur?.nom ?? obj?.civilite?.nom,
      nom: obj?.nomTuteur ?? obj?.nom,
      prenom: obj?.prenomTuteur ?? obj?.prenom,
      email: obj?.email,
      portable: obj?.portable,
      signApi: false,
      type: obj["@type"].toLowerCase(),
    });
  }, []);

  // Update data when signer is updated
  useEffect(() => {
    data[index] = signer;
    setData({ ...data, [index]: signer });
  }, [refresh]);

  return (
    <>
      <Grid container sx={{ padding: 5 }}>
        <Grid sx={{ display: "column" }} item md={4}>
          <Grid
            container
            rowSpacing={2}
            sx={{ display: "flex", flexDirection: "row" }}
          >
            <Grid item md={1}>
              <Typography variant="h4" fontWeight={"bold"}>
                {index}
              </Typography>
            </Grid>
            <Grid item md={11}>
              <Typography fontSize={"2em"}>
                {civilite} {nom} {prenom}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Grid item md={6}>
              <Typography>Envoyer au format électronique</Typography>
            </Grid>
            <Grid item md={6}>
              <Checkbox
                checked={signer.signApi ? signer.signApi : false}
                onChange={(e) => {
                  setSigner({ ...signer, signApi: e.target.checked });
                  refreshData();
                }}
              ></Checkbox>
            </Grid>
          </Grid>
        </Grid>
        {signer.signApi && (
          <>
            <Grid
              item
              md={4}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <Typography fontWeight={"bold"}>Mail*</Typography>
              <TextField
                value={signer.email ? signer.email : ""}
                onChange={(e) => {
                  setSigner({ ...signer, email: e.target.value });
                  refreshData();
                }}
              ></TextField>
            </Grid>
            <Grid
              item
              md={3}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <Grid item md={4}>
                <Typography fontWeight={"bold"}>Portable*&nbsp;</Typography>
              </Grid>
              <Grid item md={6}>
                <MuiPhoneNumber
                  size="small"
                  defaultCountry={"fr"}
                  value={signer.portable ? signer.portable : ""}
                  customInput={TextField}
                  fullWidth
                  id={"portable"}
                  name={"portable"}
                  onChange={(e) => {
                    let stringInput = e;
                    e = stringInput.replace(/[^0-9]/gi, "");
                    setSigner({
                      ...signer,
                      portable: e,
                    });
                    refreshData();
                  }}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

export default SignerDisplay;
