import { Box, Grid, Typography } from "@mui/material";
import { _STYLES } from "../../utils/_style_constants";
import { _ACTIONS, _COLORS, _FORMATS } from "../../utils/_constants";
import { SignRequestFilterForm } from "../forms/SignRequestFilterForm";
import { DataGrid, frFR } from "@mui/x-data-grid";
import React, { useContext, useRef, useState } from "react";
import moment from "moment";
import { RequestHelper } from "../../utils/requestHelper";
import { UserContext } from "../contexts/UserContext";
import SignRequestActions from "../helpers/SignRequestActions";

export function ListSignRequest() {
  const gridRef = useRef(null);
  const [rowsSignRequest, setRowsSignRequest] = useState([]);
  const requestHelper = new RequestHelper();
  const { user } = useContext(UserContext);
  const [refresh, setRefresh] = useState(false);
  const [pdfURL, setPdfURL] = useState("");
  const [showModal, setShowModal] = useState(false);

  const getStatus = (row) => {
    if (row.requestStatus === 0 || row.requestStatus === 3) {
      // Signature en attente
      let signed = 0;
      row.signatures?.forEach((signature) => {
        if (signature.statut === 1) {
        }
        if (signature.statut === 14) {
          signed += 1;
        }
      });
      // @Todo : display error ?

      if (signed !== row.signatures.length && signed !== 0) {
        return "En cours";
      }
      if (signed === row.signatures.length) {
        return "Terminé";
      }

      return "A signer";
    }
    if (row.requestStatus === 4) {
      // Signature en attente
      return "Terminé";
    }
    if (row.requestStatus === 5) {
      // Signature en attente
      return "Requète annulée par un signataire";
    }
    if (row.requestStatus === 6) {
      // Signature en attente
      return "Requète annulée par l'application";
    }
    if (row.requestStatus === 7) {
      // Signature en attente
      return "Requète expirée";
    }
    if (row.requestStatus === 8) {
      // Signature en attente
      return "Requète en erreur";
    }
  };

  const countSign = (signatures) => {
    let signed = 0;
    signatures.forEach((signature) => {
      if (signature.statut === 14) {
        signed += 1;
      }
    });
    return signed + "/" + signatures.length / 2;
  };

  const lastSignedAt = (signatures) => {
    let lastSignedAt = signatures.sort((a, b) => {
      return new Date(b.signedAt).getTime() - new Date(a.signedAt).getTime();
    })[0].signedAt;
    return lastSignedAt;
  };

  /**
   * Columns for the DataGrid component.
   */
  const columnsListSignRequest = [
    {
      field: "requestId",
      headerName: "Numéro de requète",
      flex: 1,
      headerClassName: "primaryLight",
      renderCell: (params) => {
        return (
          <strong>
            <span style={{ color: _COLORS.success }}>
              {params.row.requestId ? params.row.requestId : "N/A"}
            </span>
          </strong>
        );
      },
    },
    {
      field: "numero",
      headerName: "Numéro de dossier",
      flex: 1,
      headerClassName: "primaryLight",
      renderCell: (params) => {
        return (
          <strong>
            <span style={{ color: _COLORS.success }}>
              {params.row.mandat.numeroDossier
                ? params.row.mandat.numeroDossier
                : "N/A"}
            </span>
          </strong>
        );
      },
    },
    {
      field: "mandants",
      headerName: "Mandant(s)",
      flex: 1,
      headerClassName: "primaryLight",
      renderCell: (params) => {
        return (
          <Typography sx={{ fontWeight: "bold" }}>
            {params.row?.mandat?.mandants?.map((mandant, index) => {
              return (
                <span key={index}>
                  {mandant.nom} {mandant.prenom}{" "}
                  {index + 1 === params.row.mandat.mandants.length ? "" : ","}
                </span>
              );
            })}
          </Typography>
        );
      },
    },
    {
      field: "requestStatus",
      headerName: "Statut",
      flex: 1,
      headerClassName: "primaryLight",
      renderCell: (params) => {
        return <strong>{getStatus(params.row)}</strong>;
      },
    },
    {
      field: "adress",
      headerName: "Addresse",
      flex: 1,
      headerClassName: "primaryLight",
      style: { "overflow-wrap": "anywhere" },
      renderCell: (params) => {
        return (
          <strong>
            <span>
              {params.row.mandat?.bien?.adresse
                ? params.row.mandat.bien.adresse
                : "N/A"}
            </span>
          </strong>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Date d'envoi",
      flex: 1,
      headerClassName: "primaryLight",
      initialState:{
        sorting: {
            sortModel: [{field: 'createdAt', sort: 'desc'}],
        }
    },
      renderCell: (params) => {
        return (
          <strong>
            {params.row?.createdAt
              ? moment(params.row.createdAt).format(
                  _FORMATS.date_and_time_display
                )
              : ""}
          </strong>
        );
      },
    },
    {
      field: "signCount",
      headerName: "Nombre de signataires",
      flex: 1,
      headerClassName: "primaryLight",
      renderCell: (params) => {
        return <strong>{countSign(params.row.signatures)}</strong>;
      },
    },
    {
      field: "lastSignedAt",
      headerName: "Date dernière signature",
      flex: 1,
      type: "date",
      headerClassName: "primaryLight",
      initialState:{
        sorting: {
            sortModel: [{field: 'lastSignedAt', sort: 'desc'}],
        }
    },
    
      renderCell: (params) => {
        return (
          <strong>
            {lastSignedAt(params.row.signatures)
              ? moment(lastSignedAt(params.row.signatures)).format(
                  _FORMATS.date_and_time_display
                )
              : "Aucune signature"}
          </strong>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      headerClassName: "primaryLight",
      renderCell: (params) => (
        <strong>
          <SignRequestActions
            row={params.row}
            requestHelper={requestHelper}
            setRefresh={setRefresh}
            refresh={refresh}
            setPdfURL={setPdfURL}
            setShowModal={setShowModal}
          ></SignRequestActions>
        </strong>
      ),
    },
  ];

  return (
    <Box sx={_STYLES.mainContainer}>
      <Grid sx={_STYLES.filterFormGrid} container>
        {/** Title */}
        <Grid item xl={3}>
          <Typography
            variant="h5"
            sx={{
              padding: "15px",
              fontWeight: "bold",
              color: _COLORS.primary,
            }}
          >
            Suivi des signatures électroniques
          </Typography>
        </Grid>
        <Grid item xl={9}></Grid>
        <Grid item xl={12}>
          {/* // Filter Form */}
          <SignRequestFilterForm
            user={user}
            setRowsSignRequest={setRowsSignRequest}
            refresh={refresh}
          ></SignRequestFilterForm>
        </Grid>
      </Grid>

      {/* Spacer */}
      <Box sx={{ height: "2%" }}></Box>

      {/* Table */}
      <Box sx={_STYLES.secondaryContainer}>
        <Grid sx={{ width: "100%" }} container rowSpacing={1}>
          <Grid item xl={3}>
            <Typography
              variant="h5"
              style={{
                padding: "15px",
                fontWeight: "bold",
                color: _COLORS.primary,
              }}
            >
              Liste des signatures électroniques
            </Typography>
          </Grid>
          <Grid item xl={9}></Grid>
          <Grid item xs={12}>
            <Box sx={_STYLES.dataGridContainer}>
              <DataGrid
                getRowHeight={() => "auto"}
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                sx={{ height: "500px" }}
                rows={rowsSignRequest}
                columns={columnsListSignRequest}
                disableSelectionOnClick={true}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10,25,50, 100]}
                ref={gridRef}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
